.o-layout-wrapper {
    //flex: 1 0 auto;
    display: block;
    width: 100%;
    //height: 100%;
    margin: 0;
    padding: 0;

    @include media-breakpoint-down(md) {
        display: block;
        height: auto;
        width: 100%;
    }
}

.o-page-content {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    //flex: 1;
    overflow: hidden;

    &.home {
        padding: 0;
    }

    &.secondary {
        width: 100%;

        .o-page-content__inner {
            height: calc(100vh - 170px);
            padding: 0 60px 170px;
            margin-top: 50px;

            @include media-breakpoint-down(sm) {
                position: relative;
                top: 0;
                bottom: auto;
                left: 0;
                right: auto;
                height: auto;
                overflow-y: visible;
                padding: 0 30px 30px;
                margin-top: 50px;
            }
        }
    }

    &.photo-gallery {

        .o-page-content__inner {
            width: calc(100% - 94px);

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
        
    }
    
    .o-page-content__inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: calc(100% - 206px);
        padding: 40px 60px 110px;
        margin-top: 110px;
        overflow-y: scroll;

        @include media-breakpoint-down(sm) {
            position: relative;
            top: 0;
            bottom: auto;
            left: 0;
            right: auto;
            height: auto;
            overflow-y: visible;
            padding: 40px 30px 30px;
            margin-top: 110px;
        }
    }
}

.o-media__page-header__video {
    position: relative;
    display: block;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        z-index:1;
        background: rgba(0, 0, 0, 0.47);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .homepageVideo {
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        overflow: hidden;
        background-color: rgba(0,0,0, 1);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .o-media__page-header__fallback {
        display: none;
        width: 100%;
        height: auto;
        background-size: auto 100%;
        background-position: 19% 53%;


        @include media-breakpoint-down(sm) {
            display: block;
            min-height: 672px;
        }
    }
}

.o-hero-content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 2;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    height:100%;

    @include media-breakpoint-down(sm) {
        min-height: 600px;
    }

    .o-hero-content-inner {
        max-width: 50%;
        text-align: center;
        color: $white;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            max-width: 96%;
        }

        h1 {
            color: $white;
            font-size: 64px;
            font-weight: 700;
            line-height: 64px;

            @include media-breakpoint-down(sm) {
                font-size: 32px;
                line-height: 30px;
            }
        }

        p {
            font-size: 36px;
            letter-spacing: 0.95px;
            line-height: 45px;
            margin: 20px 0;

            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
        }

        .button {
            margin-top: 20px;
        }

        .scroll-button {
            position: absolute;
            bottom: 90px;
            margin-left: -27px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.o-featured-image {
    height: 100vh;
}

.o-contact-banner {
    position: absolute;
    display: flex;
    align-content: center;
    bottom: 0;
    width: calc(100% - 16px);
    background: $color-brand;
    color: $white;
    font-size: 16px;
    letter-spacing: 5px;
    line-height: 26px;
    text-transform: uppercase;
    padding: 16px 60px;

    @include media-breakpoint-down(sm) {
        position: relative;
        width: 100%;
        text-align: center;
        display: block;
        padding: 16px 30px;
        margin-bottom: 64px;
    }

    &:hover {
        color: $white;
            text-decoration: none;
    }

    img {
        display: inline-block;

        @include media-breakpoint-down(sm) {
            display: block;
            margin: 0 auto;
        }
    }

    .o-contact-banner__inner-text {
        display: inline-block;
        padding: 6px 14px;

        @include media-breakpoint-down(sm) {
            display: block;
            width: 100%;
        }

        a {
            color: $white;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.order-first {
    @include media-breakpoint-down(sm) {
        order: 2;
    }
}

.mobile-hidden {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.responsive-image {
    display: block;
    width: 100%; 
    height: auto;
}

.gallery {
    display: flex;
    justify-content: center;
    align-items: center;
}

#gallery {
    margin-bottom: 30px;
}

.gallery-link {
    display: block;
    width: 100%;
    max-height: 150px;
    overflow: hidden;
    margin-bottom: 25px;

    @include media-breakpoint-down(sm) {
        height:auto;
        max-height: 100%;
    }
}

.main-image {
    position: relative;
    display: block;
    width: 100%;
    max-height: 530px;
    overflow: hidden;
    margin-top:-25px;

    @include media-breakpoint-down(sm) {
        height:auto;
        max-height: 100%;
    }
}