
@font-face {
    font-family: 'okforge';
    src: url('assets/styles/fonts/okforge/okforge.eot?84871796');
    src: url('assets/styles/fonts/okforge/okforge.eot?84871796#iefix') format('embedded-opentype'),
        url('assets/styles/fonts/okforge/okforge.woff2?84871796') format('woff2'),
        url('assets/styles/fonts/okforge/okforge.woff?84871796') format('woff'),
        url('assets/styles/fonts/okforge/okforge.ttf?84871796') format('truetype'),
        url('assets/styles/fonts/okforge/okforge.svg?84871796#ms') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'okforge';
    src: url('assets/styles/fonts/okforge/okforge.svg?60454238#okforge') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "okforge";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mobile:before {
    content: '\e800';
}

/* '' */
.icon-left-open-big:before {
    content: '\e801';
}

/* '' */
.icon-right-open-big:before {
    content: '\e802';
}

/* '' */
.icon-up-open-big:before {
    content: '\e803';
}

/* '' */
.icon-down-open-big:before {
    content: '\e804';
}

/* '' */
.icon-facebook:before {
    content: '\f09a';
}

/* '' */
.icon-linkedin:before {
    content: '\f0e1';
}

/* '' */