// Color palette
$color-brand:           #c49801;
$color-brand2:          #002e62;

$color-brand3:          #15395E;
$color-brand-highlight: lighten($color-brand, 15%);
$color-brand-shadow:    darken($color-brand, 15%);
$white:                 #ffffff;
$black:                 #000000;
$gray:                  #808080;

$color-gray:            #d3d3d3;
$color-gray-light:      #ccc;
$color-gray-dark:       #272727;

$color-highlight:       rgba(255, 255, 255, 0.05);
$color-shadow:          rgba(0, 0, 0, 0.05);


// UI
$color-ui:          $color-gray-light;

// Text
$color-text:        $color-gray-dark;

// Links
$color-link:        $color-brand;
$color-link-hover:  $color-brand-shadow;

// Buttons
$color-btn:         $color-brand;
$color-btn-hover:   $color-brand-shadow;
