.button {
    transition: all 0.33s ease-in-out;
    display: inline-block;
    padding: 18px 30px 16px;
    text-decoration: none;
    background-color: transparent;
    color: $white;
    font-family: 'Open Sans', sans-serif;
    border-radius: 0;
    border: 2px solid $white;
    transition: background-color 0.2s;
    text-transform: uppercase;
    line-height: 18px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 5px;

    &:not([href]):not([tabindex]) {
        color: $color-brand3;
    }

    &:hover,
    &:focus {
        transition: all 0.33s ease-in-out;
        background-color: $white;
        color: $color-brand2;
        text-decoration: none;
    }

}

.overlay {
    border: 2px solid $white;
}
