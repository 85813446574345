img {
    max-width: 100%;
    vertical-align: middle;
}

.featured-image {
    position:relative;
    width: 100%;
    height: auto;
    display: block;

    @include media-breakpoint-down(md) {
        margin-bottom: 30px;
    }

    &.home {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: block;
        margin: 0;
    }
}
