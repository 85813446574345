.c-form__contact_wrapper {
    max-width: 90%;
}

form {
    input,
    input:focus,
    input:hover,
    input:active,
    textarea,
    textarea:focus,
    textarea:hover,
    textarea:active {
        transition: all 0.33s ease-in-out;
        outline: none !important;
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        border: 3px solid $color-gray;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        &:focus {
            transition: all 0.33s ease-in-out;
            color: $color-gray-dark;
            border: 3px solid $color-brand;
            font-weight: bold;
        }
    }

    input[type=text] {
        width: 100%;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        &:focus {
            transition: all 0.33s ease-in-out;
            color: $color-gray-dark;
        }
    }

    textarea {
        transition: all 0.33s ease-in-out;
        background-color: transparent;
        border: 3px solid $color-gray;
        padding: 4px 4px 4px 12px;
        color: $color-gray;
        font-size: 16px;
        line-height: 26px;
        height: 120px;
        width: 100%;
        font-family: 'Open Sans', sans-serif;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        &:focus {
            transition: all 0.33s ease-in-out;
            color: $color-gray-dark;
            font-weight: bold;

        }
    }

    input:not([type=submit]):not([type=button]) {
        transition: all 0.33s ease-in-out;
        background-color: transparent;
        border: 3px solid $color-gray;
        color: $color-gray;
        padding: 4px 4px 4px 12px;
        font-size: 16px;
        line-height: 26px;
        width: 100%;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        &:focus {
            transition: all 0.33s ease-in-out;
            color: $color-gray-dark;
            border: 3px solid $color-brand;
        }
    }

    label.required {
        display: inline-block;
        position: relative;

        &:after {
            display: block;
            content: '*';
            color: $white;
            position: absolute;
            top: 0;
            right: -10px;
        }
    }
}

.gform_body {
    ul.gform_fields {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: block;

        li {
            margin-bottom: 20px;
            display: inline-block;
            width: calc(50% - 1px);
            font-size: 0;

            &:nth-child(even) {
                padding-right: 14px;
            }
            &:nth-child(odd) {
                padding-left: 14px;
            }

            &.c-form-name {
                width: 100%;
                padding-left: 0;
            }

            &.c-form-message {
                padding: 0;
                width: 100%
            }

            input[type=text]{
                width: 100%;
            }
        }

        .name_first,
        .name_last {
            display: inline-block;
            width: calc(50% - 1px);
            padding-right: 14px;
        }
        .name_last { 
            padding-left: 14px;
            padding-right: 0;
        }

        .gfield_label {
            display: block;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;
            font-size: 12px;
            color: $color-brand2;
            position: relative;
            padding: 0;
            top:7px;
        }

        .gfield {
            .ginput_container_text {
                input {
                    width: 100%;
                }
            }

            .ginput_container_textarea {
                textarea {
                    width: 100%;
                }
            }

            .ginput_container_email {
                width: 100%;
            }
        }

        .gfield_label {
            .gfield_required {
                color: $color-brand2;
            }
        }
    }

    .validation_message {
        color: $color-brand;
    }
}

.gform_button {
    @extend .button;
    display: inline-block;
    white-space: nowrap;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    background-color: $color-brand2 !important;
    border: 3px solid $color-brand2 !important;
    color: $white !important;

    &:hover {
        text-decoration: none;
        background-color: $color-brand3 !important;
        border: 3px solid $color-brand3 !important;
        cursor: hand;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        float: none;
    }
}

.gfield_visibility_hidden {
    display: none;
}

.hidden_sub_label {
    display: none;
}

.gform_validation_container {
    display: none !important;
}




