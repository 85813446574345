/*!
 * Theme Name: Oklahoma Forge
 * Theme URI: oklahomaforge.com
 * Author: Cosby Harrison Marketing
 * Author URI: https://www.chcmarketing.com
 * Description: Music artist theme
 * Version: 1.0
 * License: GNU General Public License v2 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 * Text Domain:
 */


@import url("https://use.typekit.net/fpy5imi.css");

@import "settings/settings.fonts";
@import "settings/settings.global";
@import "settings/settings.colors";

@import '../../../node_modules/bootstrap/scss/bootstrap';

@import "tools/tools.mixins";

@import "generic/generic.normalize";
@import "generic/generic.box-sizing";
@import "generic/generic.reset";
@import "generic/generic.shared";

@import "elements/elements.page";
@import "elements/elements.headings";
@import "elements/elements.forms";
@import "elements/elements.links";
@import "elements/elements.lists";
@import "elements/elements.images";
@import "elements/elements.quotes";

@import "objects/objects.wrappers";
@import "objects/objects.layout";
@import "objects/objects.page-header";
@import "objects/objects.page-footer";
@import "objects/objects.media";

@import "components/components.page-title";
@import "components/components.buttons";
@import "components/components.testimonials";
@import "components/components.avatars";

@import "trumps/trumps.links";
@import "trumps/trumps.widths";
