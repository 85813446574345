h1 {
    color: #002e62;
    font-family: 'Open Sans', sans-serif;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    display: block;

    @include media-breakpoint-down(md) {
        @include font-size(36px);
    }
}

h2 {
    display: block;
    color: $color-brand2;
    font-family: 'Open Sans', sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
        font-size: 30px;
    }
}

h3 {
    display: block;
    color: $color-brand2;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

h4 {
    display: block;
    color: $color-brand2;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
}

h5 {
    color: $color-brand2;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}

h6 {
    color: $color-brand2;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
}
