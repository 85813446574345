html {
    height: 100%;
    font-size: ($base-font-size / 20px) * 1em; /* [1] */
    //line-height: $base-line-height / $base-font-size; /* [1] */
    margin: 0 !important;
    padding: 0;
    color: $color-text;
}

body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: $white;
    color: #272727;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    letter-spacing: 0.53px;
    line-height: 30px;

    @include media-breakpoint-down(sm) {
        font-size: 14px;
        letter-spacing: 0.38px;
        line-height: 22px;
    }
}