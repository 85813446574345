// Variables

$text-arrow-space: 16px;
$shaft-width: 1px;
$newshaft-width: 64px;
$shaft-thickness: 1px;
$arrow-head-width: 8px;
$arrow-head-thickness: $shaft-thickness;

.homepage {
    .o-page-header__link--logo {
        path {
            fill: $white !important;
        }
    }
}

.link--logo {
    display: block;
    padding: 30px 60px;

    @include media-breakpoint-down(sm) {
        margin: 30px auto;
        text-align: center;
        padding: 0;
    }

    &.nav {
        padding: 0;
        margin: 0;
        width: 64%;

        @include media-breakpoint-down(sm) {
            position: fixed;
            display: block;
            top: 15px;
            margin: 0 auto;
            width: 100%;
            text-align: center;
        }

        svg {
            width: 100%;
            height: auto;

            @include media-breakpoint-down(sm) {
                width: 162px;
                height: auto;
                margin: 0 auto;
            }
        }

        path {
            fill: $white !important;
        }
    }

    .o-page-header__link--logo {
        transition: all 0.33s ease-in-out;
        width: 210px;
        height: auto;

        @include media-breakpoint-down(sm) {
            width: 162px;
            margin: 0 auto;
        }

        &:hover {
            transition: all 0.33s ease-in-out;
        }
    }
}

.o-page-header {
    transition: all 0.33s ease-in-out;
    position: absolute;
    z-index: 99;
    
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.o-menu-wrapper {
    position: fixed;
    z-index: 999;
    right: 0;
    transition: all 0.33s ease-in-out;
    width: 94px;
    height: 100%;
    background: rgba(0,46,98, .85);
    margin: 0;
    flex:1;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        position: fixed;
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 64px;
        text-align: center;
        background: rgba(0, 46, 98, 1);
    }

    &.open {
        transition: all 0.33s ease-in-out;
        width: 50%;

        @include media-breakpoint-down(sm) {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
        }

        .btn-menu-wrapper {
            transform: translateY(-50%) rotate(-90deg);

            @include media-breakpoint-down(sm) {
                transform: translateY(-50%) rotate(0deg);
            }

            &:hover {
                
                span {
                    &.btn--nav-image {
                        transform: rotate(90deg);

                        &.alt {
                            transform: rotate(-90deg);
                        }

                        img {
                            transition: all 0.55s;
                            top: 0px;
                            left: 0;
                        }
                    }
                }
            }
            .open-menu {
                display: none;
            }

            .close-menu {
                display: inline-block;
            }
        }

        .o-menu-wrapper__inner {
            transition: all 0.33s ease-in-out;
            margin-left: 14%;

            @include media-breakpoint-down(sm) {
                transition: all 0.33s ease-in-out;
                opacity: 1;
                height: 100%;
                margin: 0 auto;
                overflow: visible;
            }
        }
    }

    .btn-menu-wrapper {
        position: absolute;
        z-index: 999;
        top: 50%;
        left: -133px;
        display: block;
        text-transform: uppercase;
        font-size: 24px;
        color: $color-brand;
        width: 370px;
        text-align: center;
        transform: translateY(-50%) rotate(-90deg);
        //background: lime;

        @include media-breakpoint-down(sm) {
            transform: none;
            top: auto;
            bottom: 20px;
            left: auto;
            width: 100%;
            padding-top: 21px;
        }

        &:hover {
            text-decoration: none;

            span {
                &.btn--nav-image {
                    img {
                        transition: all 0.55s;
                        top: 0px;
                    }
                }
            }
        }

        span {
            display: inline-block;
            margin: 0;

            &.btn--nav-image {
                position: relative;
                display: inline-block;
                width: 18px;
                height: 100px;
                transform: rotate(-90deg);
                overflow: hidden;

                @include media-breakpoint-down(sm) {
                    display: none;
                }

                &.alt {
                    transform: rotate(90deg);
                }

                img {
                    transition: all 0.55s;
                    position: absolute;
                    width: 100%;
                    height: auto;
                    top: 106px;
                    left: 0;
                }
            }

            &.open-menu {
                display: inline-block;
                position: relative;
                z-index: 2;
                top: -41px;

                @include media-breakpoint-down(sm) {
                    top: auto;
                }
            }

            &.close-menu {
                display: none;
                position: relative;
                z-index: 2;
                top: -41px;

                @include media-breakpoint-down(sm) {
                    top: 20px;
                }
            }

            span {
                display: block;
                padding: 0 60px;
            }
        }
    }

    .o-menu-wrapper__inner {
        transition: all 0.33s ease-in-out;
        position: relative;
        height: 100%;
        width: 100%;
        margin-left: 98px;
        overflow: hidden;
        display: flex;
        flex:1;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            transition: all 0.33s ease-in-out;
            opacity: 0;
            height: 0;
            margin: 0 auto;
            overflow: hidden
        }

        .o-menu-wrapper__inner_container {
            width: 100%;

            .o-menu-footer-info {
                position: absolute;
                bottom: 60px;

                @include media-breakpoint-down(md) {
                    position: relative;
                    bottom: auto;
                }
            }
        }

        .nav--main {
            display: block;

            @include media-breakpoint-down(sm) {
                margin-top: 40px;
            }

            h4 {
                color: $color-brand;
                font-size: 16px;
                letter-spacing: 7.5px;
                text-transform: uppercase;
            }

            .nav__links {
                margin: 0;
                padding: 0;
                text-align: left;
                color: $white;
                margin-bottom: 14%;

                @include media-breakpoint-down(md) {
                    display: block;
                    width: 100%;
                    text-align: center;
                }

                li {
                    display: block;
                    margin: 16px 0;

                    @media (max-width: 320px) {
                        margin: 10px 0;
                    }

                    &:last-child {
                        a {
                            padding-right: 0;
                        }
                    }

                    &.phone {
                        a {
                            color: $color-brand;
                        }
                    }

                    a {
                        transition: all 0.33s ease-in-out;
                        font-size: 28px;
                        font-weight: 700;
                        letter-spacing: 8.75px;
                        text-transform: uppercase;
                        color: $white;
                        padding: 4px 0;

                        @include media-breakpoint-down(md) {
                            font-size: 12px;
                            letter-spacing: 2px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 24px;
                            font-weight: 700;
                            letter-spacing: 2px;
                        }
                        
                        @media (max-width: 320px) {
                            font-size: 18px;
                        }

                        &:hover {
                            transition: all 0.33s ease-in-out;
                            text-decoration: none;
                            color: $color-brand;
                        }
                    }
                }
            }
        }

        .o-menu__address {
            color: $white;
            font-size: 16px;
            line-height: 26px;

            @include media-breakpoint-down(sm) {
                font-size: 12px;
            }
        }

        .social-links {

            h4 {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            ul {
                margin: 0 0 0 -4px;
                padding: 0;
                list-style-type: none;

                @include media-breakpoint-down(sm) {
                    margin: 20px 0 0 -4px;
                }

                li {
                    display: inline-block;

                    a {
                        color: $white;
                        font-size: 24px;
                        
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            
        }

        h4 {
            color: $white;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 8px;
        }

        .copyright {
            margin-top: 22px;
            color: $white;
            font-size: 14px;
            line-height: 26px;

            @include media-breakpoint-down(sm) {
                font-size: 10px;
                padding: 0 16px;
            }

            @media (max-width: 320px) {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }    
}

// The Arrow

.the-arrow {
    width: $shaft-width;
    transition: all 0.2s;

    &.-left {
        position: absolute;
        top: 60%;
        left: 0;

        >.shaft {
            width: 0;
            background-color: $color-brand;

            &:before,
            &:after {
                width: 0;
                background-color: $color-brand;
            }

            &:before {
                transform: rotate(0);
            }

            &:after {
                transform: rotate(0);
            }
        }
    }

    &.-right {
        top: 3px;

        >.shaft {
            width: $shaft-width;
            transition-delay: 0.2s;

            &:before,
            &:after {
                width: $arrow-head-width;
                transition-delay: 0.3s;
                transition: all 0.5s;
            }

            &:before {
                transform: rotate(40deg);
            }

            &:after {
                transform: rotate(-40deg);
            }
        }
    }

    >.shaft {
        background-color: $color-brand;
        display: block;
        height: $shaft-thickness;
        position: relative;
        transition: all 0.2s;
        transition-delay: 0;
        will-change: transform;

        &:before,
        &:after {
            background-color: $color-brand;
            content: '';
            display: block;
            height: $arrow-head-thickness;
            position: absolute;
            top: 0;
            right: 0;
            transition: all 0.2s;
            transition-delay: 0;
        }

        &:before {
            transform-origin: top right;
        }

        &:after {
            transform-origin: bottom right;
        }
    }
}


// Animated Arrow Button

.animated-arrow {
    display: inline-block;
    color: $color-brand;
    font-size: 1.25em;
    font-style: italic;
    text-decoration: none;
    position: relative;
    transition: all 0.2s;

    &:hover {
        color: $white;

        >.the-arrow.-left {
            >.shaft {
                width: $newshaft-width;
                transition-delay: 0.1s;
                //background-color: $white;

                &:before,
                &:after {
                    width: $arrow-head-width;
                    transition-delay: 0.1s;
                    //background-color: $white;
                }

                &:before {
                    transform: rotate(40deg);
                }

                &:after {
                    transform: rotate(-40deg);
                }
            }
        }

        >.main {
            transform: translateX($shaft-width + $text-arrow-space);
            transform: translateX($newshaft-width + $text-arrow-space);

            >.the-arrow.-right {
                >.shaft {
                    width: 0;
                    transform: translateX(200%);
                    transition-delay: 0;

                    &:before,
                    &:after {
                        width: 0;
                        transition-delay: 0;
                        transition: all 0.1s;
                    }

                    &:before {
                        transform: rotate(0);
                    }

                    &:after {
                        transform: rotate(0);
                    }
                }
            }
        }
    }

    >.main {
        display: flex;
        align-items: center;
        transition: all 0.2s;

        >.text {
            margin: 0 $text-arrow-space 0 0;
            line-height: 1;
        }

        >.the-arrow {
            position: relative;
        }
    }
}